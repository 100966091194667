import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  text: string
}

const LoadingIndicator = ({ text = `Loading...` }: Props) => (
  <Box
    sx={{
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
    }}
  >
    <Typography
      sx={{
        padding: 2,
        fontFamily: `Nunito`,
        fontSize: `18px`,
        margin: `15px 0`,
        textAlign: `center`,
      }}
    >
      {text}
    </Typography>
    <CircularProgress />
  </Box>
);

export default LoadingIndicator;
