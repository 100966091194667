interface Folder {
  id: string;
  name?: string | null;
  parent?: Folder | null;
}
interface Props {
  userFolderId: string;
  orgFolderId: string;
  wlFolderData: {
    id: string;
    name?: string | null;
    typeId?: number | null;
    treeObjectId: string;
    folderPath?: Folder[] | null;
  } | null;
}
interface useWLFolderReturn {
  canAccessWL: boolean;
  wlFolderPath: string;
}
const useWLFolder = ({ // This hook is used to format the WL "file path" copy
  userFolderId,
  orgFolderId,
  wlFolderData,
}: Props): useWLFolderReturn => {
  let canAccessWL = false;
  let wlFolderPath = ``;
  if (wlFolderData) {
    const wlFolders = wlFolderData?.folderPath || [];
    const wlFolderId = wlFolderData?.id;
    const takeIdReturnParent = (id: string | null): string => {
      if (!id) {
        return wlFolderId;
      }
      const itemIndex: number | null = wlFolders.findIndex(
        (folder: Folder) => id === folder.id,
      );
      if (wlFolders[itemIndex].parent === null) {
        const temp = wlFolderPath;
        if (wlFolders[itemIndex].id === userFolderId) { // user root folder names are emails
          wlFolderPath = `My Watchlists/${temp}`;
          canAccessWL = true;
        } else if (wlFolders[itemIndex].id === orgFolderId) { // org root folder names are malformed
          wlFolderPath = `Shared Watchlists/${temp}`;
          canAccessWL = true;
        }
        return wlFolderPath;
      }
      const temp = wlFolderPath;
      wlFolderPath = `${wlFolders[itemIndex].name}/${temp}`;
      return takeIdReturnParent(wlFolders[itemIndex].parent!.id);
    };
    takeIdReturnParent(wlFolderId);
  }
  return {
    canAccessWL,
    wlFolderPath,
  };
};

export default useWLFolder;
