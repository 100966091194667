import { appErrorMessageVar, isAppErrorVar } from 'apollo/cache';

const throwAppError = (message: string) => {
  isAppErrorVar(true);
  appErrorMessageVar(message);

  throw new Error(message);
};

export default throwAppError;
