import {
  sortBy,
  isEmpty,
  find,
} from 'lodash';
import { TargetAudience } from "../../../types";
import {
  NIELSEN_AGE_GROUPS,
  AUDIENCE_PROVIDER,
  GENDER_VALUES,
  GENDER_KEYS,
} from '../constants';
import { AgeRangeAggregate } from './types';

export const filterDataByAgeAndGender = (
  data: AgeRangeAggregate[],
  targetAudience: TargetAudience,
): {
  filteredData: AgeRangeAggregate[];
  genderKeys: string[];
} => {
  if (!data || !targetAudience) {
    return {
      filteredData: [],
      genderKeys: [],
    };
  }
  const {
    ageGroup: targetAge,
    gender: targetGender,
    datasource,
  } = targetAudience;
  const genderValues: number[] = [];
  const genderKeys: string[] = [];
  let ageGroupValues: any[] = [];
  if (targetGender) {
    if (!genderValues.includes(targetGender)) {
      if (targetGender === 3) {
        genderValues.push(1);
        genderValues.push(2);
        genderKeys.push(`men`);
        genderKeys.push(`women`);
      } else {
        genderValues.push(targetGender);
        genderKeys.push(GENDER_KEYS[targetGender as keyof typeof GENDER_KEYS]);
      }
    }
  }
  const [min, max] = targetAge;
  /**
   * Push all values in range (min, max) to the array. [0, 2] => 0, 1, 2
   */
  for (let i = min; i <= max; i += 1) {
    if (!ageGroupValues.includes(i)) {
      ageGroupValues.push(i);
    }
  }
  const ageGroupLabels: string[] = [];
  ageGroupValues = ageGroupValues.sort();
  ageGroupValues.forEach((key: number) => {
    // Nielsen and Numeris are different in age ranges
    if (key === 0) {
      ageGroupLabels.push(`18-20`);
      ageGroupLabels.push(`21-24`);
    } else if (key === 3) {
      ageGroupLabels.push(`45-49`);
      ageGroupLabels.push(`50-54`);
    } else {
      ageGroupLabels.push(
        NIELSEN_AGE_GROUPS[key as keyof typeof NIELSEN_AGE_GROUPS],
      );
    }
  });
  // apply age filter
  const filteredData = data
    .filter((ageGroupAggregate: { key: string }) => ageGroupLabels.includes(ageGroupAggregate.key))
    .map((ageGroupAggregate) => {
      // apply gender filter
      // recalculate audience
      const {
        gender: { buckets, doc_count_error_upper_bound, sum_other_doc_count },
        doc_count,
        key,
      } = ageGroupAggregate;
      let { audience } = ageGroupAggregate;
      const recalculatedBuckets = buckets.filter(
        (bk: { key: string }) => genderValues.includes(GENDER_VALUES[bk.key as keyof typeof GENDER_VALUES])
        || genderValues.includes(GENDER_VALUES.both),
        // bk.key = men or women; GENDER_VALUES = {`men`: 1, `women`: 2}
      );
      const gender = {
        doc_count_error_upper_bound,
        sum_other_doc_count,
        buckets: recalculatedBuckets,
      };
      let sumAudience = 0;
      recalculatedBuckets.forEach((bk: { audience: { value: number } }) => {
        sumAudience += bk.audience.value;
      });
      audience = { value: sumAudience };
      return {
        gender,
        audience,
        doc_count,
        key,
      };
    });

  if (datasource !== AUDIENCE_PROVIDER.NUMERIS) {
    /** Use Nielsen age ranges by default if targeting demo
     * regroup 18-20 + 21-24 -> 18-24
     * regroup 45-49 + 50-54 -> 45-54
     */
    let finalData = [];
    const dataToBeMerged1824: AgeRangeAggregate = {
      gender: {
        doc_count_error_upper_bound: 0,
        sum_other_doc_count: 0,
        buckets: [],
      },
      audience: { value: 0 },
      doc_count: 0,
      key: `18-24`,
    };
    const dataToBeMerged4554: AgeRangeAggregate = {
      gender: {
        doc_count_error_upper_bound: 0,
        sum_other_doc_count: 0,
        buckets: [],
      },
      audience: { value: 0 },
      doc_count: 0,
      key: `45-54`,
    };

    filteredData.map((row) => {
      if (row.key === `18-20` || row.key === `21-24`) {
        // group up into dataToBeMerged1824
        dataToBeMerged1824.audience = {
          value: dataToBeMerged1824.audience.value + (row.audience.value || 0),
        };
        dataToBeMerged1824.doc_count = row.doc_count;
        // merge buckets
        genderKeys.map((genderKey) => {
          const bucket = find(row.gender.buckets, (bk) => bk.key === genderKey);
          if (bucket && !isEmpty(bucket)) {
            dataToBeMerged1824.gender.buckets.push(bucket);
          }
          return targetGender;
        });
      } else if (row.key === `45-49` || row.key === `50-54`) {
        // group up into dataToBeMerged4554
        dataToBeMerged4554.audience = {
          value: dataToBeMerged4554.audience.value + (row.audience.value || 0),
        };
        dataToBeMerged4554.doc_count = row.doc_count;
        // merge buckets
        genderKeys.map((genderKey) => {
          const bucket = find(row.gender.buckets, (bk) => bk.key === genderKey);
          if (bucket && !isEmpty(bucket)) {
            dataToBeMerged4554.gender.buckets.push(bucket);
          }
          return targetGender;
        });
      } else {
        finalData.push(row);
      }
      return row;
    });
    if (dataToBeMerged4554.doc_count > 0) finalData.push(dataToBeMerged4554);
    if (dataToBeMerged1824.doc_count > 0) finalData.push(dataToBeMerged1824);
    finalData = sortBy(finalData, (item) => item.key);
    return {
      filteredData: finalData,
      genderKeys,
    };
  }
  return {
    filteredData,
    genderKeys,
  };
};

export const createAudienceByAge = (
  data: AgeRangeAggregate[],
  targetAudience: TargetAudience,
): AgeRangeAggregate[] => {
  const { filteredData } = filterDataByAgeAndGender(data, targetAudience);
  return filteredData;
};

// const genderCopy = (data: GenderBucket[]) => {
//   const colorList = [`#3F51B5`, `#00BCD4`];
//   const formattedData = data.map((bucket, i) => ({
//     id: bucket.key === `men` ? `Male` : `Female`,
//     key: bucket.key === `men` ? `Male` : `Female`,
//     value: bucket.audience.value,
//     color: colorList[i],
//   }));

//   return formattedData;
// };

export const createAudienceByGender = (
  data: AgeRangeAggregate[],
  targetAudience: TargetAudience,
) => {
  const genderData: {
    key: string;
    audience: { value: number };
    doc_count: number;
  }[] = [];
  const { filteredData, genderKeys } = filterDataByAgeAndGender(
    data,
    targetAudience,
  );
  if (genderKeys) {
    genderKeys.map((key: string) => {
      const genderAggregate = { key, audience: { value: 0 }, doc_count: 0 };
      // recalculate
      filteredData.map((filteredAgeRangeAggregate) => {
        const { buckets } = filteredAgeRangeAggregate.gender;
        buckets.map((bucket) => {
          if (bucket.key === key) {
            genderAggregate.audience.value += bucket.audience.value;
          }
          genderAggregate.doc_count = bucket.doc_count;
          return bucket;
        });
        return filteredAgeRangeAggregate.key;
      });
      genderData.push(genderAggregate);
      return key;
    });
    // return genderCopy(genderData);
    return genderData;
  }
  return filteredData;
};
