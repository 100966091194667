type FormattedDataType = {
  category: string,
  key: string,
  value: number,
}

const defineVerificationStatus = (data: any[], type: string) => {
  const formattedData: FormattedDataType[] = [];

  data.forEach((bucket: any) => {
    const bucketKey = bucket.key;
    let value;
    if (type === `mentionsByType`) {
      value = bucket.doc_count;
    } else if (type === `impressionsByType`) {
      value = bucket.sort_aggregation.value;
    } else if (type === `mentionsByDate` || type === `impressionsByDate`) {
      value = bucket.mentionDate.buckets;
    }

    switch (bucketKey) {
      case 1: formattedData.push({
        category: `Pending Verification`,
        key: `pendingVerification`,
        value,
      });
        break;
      case 2: formattedData.push({
        category: `Verified`,
        key: `verified`,
        value,
      });
        break;
      case 3: formattedData.push({
        category: `Needs Review`,
        key: `needsReview`,
        value,
      });
        break;
      case 4: formattedData.push({
        category: `Request Bonus`,
        key: `requestBonus`,
        value,
      });
        break;
      case 5: formattedData.push({
        category: `Invalid`,
        key: `invalid`,
        value,
      });
        break;
      case 6: formattedData.push({
        category: `Processing Verification`,
        key: `processingVerification`,
        value,
      });
        break;
      case 7: formattedData.push({
        category: `Auto Verified`,
        key: `autoVerified`,
        value,
      });
        break;
      case 8: formattedData.push({
        category: `Paid`,
        key: `paid`,
        value,
      });
        break;
      case 9: formattedData.push({
        category: `Earned`,
        key: `earned`,
        value,
      });
        break;
      case 10: formattedData.push({
        category: `Logged Spots`,
        key: `loggedSpots`,
        value,
      });
        break;
      case 11: formattedData.push({
        category: `Live Chatter`,
        key: `liveChatter`,
        value,
      });
        break;
      default:
        formattedData.push({
          category: `Unknown Status`,
          key: `unknownStatus`,
          value,
        });
    }
  });
  return formattedData;
};

export default defineVerificationStatus;
