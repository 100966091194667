import { useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { isTAFilteredVar, targetAudienceVar, chartsLoadingStatusVar } from 'apollo/cache';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import useNielsenArbitronAnalytics from 'hooks/useNielsenArbitronAnalytics';
import ChartContainer from 'features/Charts/components/ChartContainer';
import { createAudienceByGender } from 'features/Charts/AudienceCharts/helpers';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import { audienceByGenderAggregate, targetedAggregate } from '../constants';

interface AudienceType {
  value: number,
}

interface AudienceByGenderType {
  audience: AudienceType,
  doc_count: number,
  key: string,
}

interface FormattedDataType {
  category: string,
  value: number,
}

const AudienceByGenderNielsen = () => {
  const { watchlistId } = useParams();
  const { searchMentions, loading, error } = useNielsenArbitronAnalytics(
    // check if WL is target audience filtered to supply query payload
    isTAFilteredVar()
      ? {
        watchlistId,
        aggregate: targetedAggregate,
      }
      : {
        watchlistId,
        aggregate: audienceByGenderAggregate,
      },
  );
  // check if WL is target audience filtered to extract data buckets
  const audienceByGender = isTAFilteredVar()
    ? createAudienceByGender(
      searchMentions?.jsondata.aggregations.audienceByAge.buckets,
      targetAudienceVar(),
    )
    : searchMentions?.jsondata.aggregations.audienceByGender.buckets;
  const isLoading = !audienceByGender || loading;

  // Instantiate chart
  useLayoutEffect(() => {
    // Define data
    const formattedData: FormattedDataType[] = isLoading
      ? []
      : audienceByGender.map((audienceValue: AudienceByGenderType) => {
        const formattedCategoryName = (audienceValue.key).charAt(0).toUpperCase() + (audienceValue.key).slice(1);
        return {
          category: formattedCategoryName,
          value: audienceValue.audience.value,
        };
      });

    const root = am5.Root.new(`chartdiv16`);

    setDataIndicator(formattedData, root, isLoading);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        innerRadius: am5.percent(50),
        radius: am5.percent(80),
      }),
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: `Series`,
        valueField: `value`,
        categoryField: `category`,
      }),
    );
    series.data.setAll(formattedData);

    // Add animation
    series.appear();

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        y: am5.percent(75),
        x: am5.percent(70),
        layout: root.verticalLayout,
      }),
    );

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    legend.labels.template.setAll({
      fontSize: 14,
    });

    legend.valueLabels.template.setAll({
      fontSize: 14,
      marginLeft: 5,
    });

    legend.data.setAll(series.dataItems);

    series.labels.template.setAll({
      text: `{value}`,
      oversizedBehavior: `wrap`,
    });

    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      audienceByGender: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);

    return () => {
      root.dispose();
    };
  }, [isLoading, audienceByGender]);

  return (
    <ChartContainer
      chartId="chartdiv16"
      title="Audience by Gender - Nielsen"
      chartStyles={{
        height: `250px`,
      }}
      error={error}
    />
  );
};

export default AudienceByGenderNielsen;
