import html2canvas from 'html2canvas';
import { jsPDF as JSPDF, RGBAData } from 'jspdf';
import { formatDate } from './dateFormatter';

type Props = {
  name: string
  startDateTime: string
  stopDateTime: string
}

const createPDF = ({ name, startDateTime, stopDateTime }: Props) => {
  const pdf = new JSPDF({
    orientation: `landscape`,
  });

  const today = new Date();
  const startDate = new Date(startDateTime);
  const stopDate = new Date(stopDateTime);
  const formattedToday = formatDate(today, false);
  const formattedStartDate = formatDate(startDate, false);
  const formattedStopDate = formatDate(stopDate, false);
  const formattedYesterday = formatDate(today, true);
  const throughDate = formattedYesterday > formattedStopDate ? formattedStopDate : formattedYesterday;

  let firstPage: string | HTMLCanvasElement | HTMLImageElement | Uint8Array | RGBAData;
  let secondPage: string | HTMLCanvasElement | HTMLImageElement | Uint8Array | RGBAData;
  let thirdPage: string | HTMLCanvasElement | HTMLImageElement | Uint8Array | RGBAData;

  const firstPageDiv: any = document.getElementById(`report-page-1`);
  const secondPageDiv: any = document.getElementById(`report-page-2`);
  const thirdPageDiv: any = document.getElementById(`report-page-3`);

  html2canvas(firstPageDiv).then((canvas) => {
    firstPage = canvas.toDataURL(`image/png`);
  });

  html2canvas(secondPageDiv).then((canvas) => {
    secondPage = canvas.toDataURL(`image/png`);
  });

  if (thirdPageDiv) { // only add thirdPage when needed
    html2canvas(thirdPageDiv).then((canvas) => {
      thirdPage = canvas.toDataURL(`image/png`);
    });
  }

  const leftMargin = 10;
  const topMargin = 20;
  const pdfWidth = pdf.internal.pageSize.width - 20; // subtract 20 to account for margin
  const pdfHeight = 0; // prevents vertical stretch

  setTimeout(() => {
    pdf.setFontSize(10);
    pdf.text([
      `Generated on ${formattedToday}`,
      `Contains data for ${name} watchlist with a start date of ${formattedStartDate} and an end date of ${formattedStopDate}`,
      `Contains data starting on ${formattedStartDate} through ${throughDate}`,
    ], pdfWidth + 10, 8, { align: `right` });
    pdf.addImage(firstPage, `PNG`, leftMargin, topMargin, pdfWidth, pdfHeight);
    pdf.addPage();
    pdf.text([
      `Generated on ${formattedToday}`,
      `Contains data for ${name} watchlist with a start date of ${formattedStartDate} and an end date of ${formattedStopDate}`,
      `Contains data starting on ${formattedStartDate} through ${throughDate}`,
    ], pdfWidth + 10, 8, { align: `right` });
    pdf.addImage(secondPage, `PNG`, leftMargin, topMargin, pdfWidth, pdfHeight);
    if (thirdPageDiv) { // only add thirdPage when needed
      pdf.addPage();
      pdf.text([
        `Generated on ${formattedToday}`,
        `Contains data for ${name} watchlist with a start date of ${formattedStartDate} and an end date of ${formattedStopDate}`,
        `Contains data starting on ${formattedStartDate} through ${throughDate}`,
      ], pdfWidth + 10, 8, { align: `right` });
      pdf.addImage(thirdPage, `PNG`, leftMargin, topMargin, pdfWidth, pdfHeight);
    }
    pdf.save(`reportDownload.pdf`);
  }, 800);
};

export default createPDF;
