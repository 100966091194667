/* eslint-disable react/require-default-props */
import { useReactiveVar } from '@apollo/client';
import '@fontsource/nunito/500.css';
import '@fontsource/dosis/500.css';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { chartsLoadingStatusVar } from 'apollo/cache';
import createPDF from './helpers/createPDF';

type Props = {
  name: string
  startDateTime: string
  stopDateTime: string
  title?: string
  description?: string
}

const DescriptionBox = ({
  title,
  description,
  name,
  startDateTime,
  stopDateTime,
}: Props) => {
  const chartLoadingStatus = useReactiveVar(chartsLoadingStatusVar);
  const isDisabled = Object.values(chartLoadingStatus).includes(true);
  return (
    <Box sx={{
      padding: `0px 20px`,
      margin: `20px 0px`,
      display: `flex`,
      justifyContent: `space-between`,
    }}
    >
      <Box>
        <Box sx={{
          fontFamily: `'Nunito', sans-serif`,
          marginBottom: `8px`,
        }}
        >
          {title}
        </Box>
        <Box sx={{
          color: `#7D7D7D`,
          fontFamily: `Dosis, sans-serif`,
        }}
        >
          {description}
        </Box>
      </Box>
      <Box>
        <Button
          disabled={isDisabled}
          onClick={() => createPDF({ name, startDateTime, stopDateTime })}
        >
          <FileDownloadIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default DescriptionBox;
