import { useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { isTAFilteredVar, timezoneVar, chartsLoadingStatusVar } from 'apollo/cache';
import useWatchlist from 'hooks/useWatchlist';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import useAudienceAnalytics from 'hooks/useAudienceAnalytics';
import ChartContainer from 'features/Charts/components/ChartContainer';
import useUser from 'hooks/useUser';

export interface ImpressionsType {
  value: number,
}

export interface DatesImpressionsType {
  doc_count: number,
  key: number,
  key_as_string: string,
  impressions: ImpressionsType,
}

export interface FormattedDataType {
  category: string,
  value: number,
}

const DatesImpressions = () => {
  const { watchlistId } = useParams();

  const {
    orgInfo: {
      orgData,
    },
  } = useUser();

  const isNielsenUser = orgData?.dataSources.includes(`arbitron`);

  const {
    watchlist,
    loading: loadingWatchlist,
    error: errorWatchlist,
  } = useWatchlist(watchlistId || ``);

  const {
    searchMentions,
    loading: loadingMentions,
    error: errorMentions,
  } = useAudienceAnalytics({
    watchlistId,
    aggregate: [
      {
        name: `trackingUnitTop6Impressions`,
        field: `trackingUnitName`,
        operator: `term`,
        limit: 6,
        sort: {
          criteria: `sum`,
          field: isTAFilteredVar()
            ? `targetAudience.impressions`
            : `impressions`,
          name: `impressions`,
        },
        aggregate: [
          {
            operator: `sum`,
            field: `impressions`,
            name: `impressions`,
          },
          {
            operator: `date`,
            field: `mentionDate`,
            format: `MM/dd`,
            timezone: timezoneVar(),
            interval: `1d`,
            aggregate: [
              {
                operator: `sum`,
                field: isTAFilteredVar()
                  ? `targetAudience.impressions`
                  : `impressions`,
                name: `impressions`,
              },
            ],
          },
        ],
      },
    ],
  });

  const isLoading = useMemo(
    () => loadingWatchlist || !watchlist || !searchMentions || loadingMentions,
    [loadingWatchlist, watchlist, searchMentions, loadingMentions],
  );

  const watchlistName = watchlist?.name;
  const mentionsData: any[] = searchMentions?.jsondata.aggregations.trackingUnitTop6Impressions.buckets;
  const datesImpressions: DatesImpressionsType[] = useMemo(() => {
    if (mentionsData !== undefined && mentionsData.length > 0) {
      return mentionsData[0].mentionDate.buckets;
    }

    return [];
  }, [mentionsData]);

  // Instantiate chart
  useLayoutEffect(() => {
    // Define data
    const formattedData: FormattedDataType[] = isLoading ? [] : datesImpressions.map((impressionByDay: DatesImpressionsType) => ({
      category: impressionByDay.key_as_string,
      value: impressionByDay.impressions.value,
    }));

    const root = am5.Root.new(`chartdiv1000`);

    setDataIndicator(formattedData, root, isLoading);

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    const yRenderer = yAxis.get(`renderer`);
    yRenderer.ticks.template.setAll({
      minPosition: 0.1,
      visible: true,
    });
    yRenderer.labels.template.setAll({
      minPosition: 0.1,
    });

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: `category`,
      }),
    );
    xAxis.data.setAll(formattedData);
    xAxis.set(`tooltip`, am5.Tooltip.new(root, {
      themeTags: [`axis`],
    }));

    // Create series
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: `Series`,
        xAxis,
        yAxis,
        valueYField: `value`,
        categoryXField: `category`,
        fill: am5.color(0x638DD7),
        stroke: am5.color(0x638DD7),
        tooltip: am5.Tooltip.new(root, {
          labelText: `${watchlistName}: {valueY}`,
        }),
      }),
    );

    series.strokes.template.setAll({
      strokeWidth: 2,
    });

    series.data.setAll(formattedData);

    // Add animation
    series.appear();

    // Add cursor
    chart.set(`cursor`, am5xy.XYCursor.new(root, {}));

    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      datesImpressions: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);
    return () => {
      root.dispose();
    };
  }, [
    watchlistName,
    datesImpressions,
    isLoading,
  ]);

  return (
    <ChartContainer
      chartId="chartdiv1000"
      title={`Dates - ${isNielsenUser ? `Nielsen` : `Numeris`} Impressions`}
      chartStyles={{
        height: `250px`,
      }}
      error={errorWatchlist || errorMentions}
    />
  );
};

export default DatesImpressions;
