import { useLayoutEffect, useMemo } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import { useParams } from 'react-router';
import '@fontsource/nunito/500.css';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import useAnalytics from 'hooks/useAnalytics';
import ChartContainer from 'features/Charts/components/ChartContainer';
import { chartsLoadingStatusVar } from 'apollo/cache';

type impressionType = {
  value: number,
}

type mediaSourceMentionsType = {
  key: string,
  doc_count: number,
  impressions: impressionType,
}

type formattedDataType = {
  mediaType: string,
  value: number,
}

const MediaSourceTypeMentionsChart = () => {
  const { watchlistId } = useParams();

  const {
    searchMentions,
    loading,
    error,
  } = useAnalytics({
    watchlistId,
    aggregate: [
      {
        field: `mediaSourceTypeName`,
        operator: `term`,
        limit: 10,
        aggregate: [
          {
            operator: `sum`,
            field: `impressions`,
          },
        ],
      },
    ],
  });

  const isLoading = useMemo(() => loading || !searchMentions, [loading, searchMentions]);

  const data: mediaSourceMentionsType[] | undefined = searchMentions?.jsondata.aggregations.mediaSourceTypeName.buckets;

  const formattedData: formattedDataType[] = useMemo(() => {
    if (data !== undefined) {
      return data.map((bucket) => ({
        mediaType: bucket.key,
        value: bucket.doc_count,
      }));
    }

    return [];
  }, [data]);

  // Instantiate chart
  useLayoutEffect(() => {
    const root = am5.Root.new(`chartdiv3`);
    setDataIndicator(formattedData, root, isLoading);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        innerRadius: am5.percent(50),
      }),
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: `Series`,
        valueField: `value`,
        categoryField: `mediaType`,
      }),
    );
    series.data.setAll(formattedData);

    series.labels.template.setAll({
      text: `{value}`,
      oversizedBehavior: `wrap`,
    });

    // Add legend
    const legend = chart.children.push(am5.Legend.new(root, {
      y: am5.percent(15),
      x: am5.percent(70),
      layout: root.verticalLayout,
    }));

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    legend.labels.template.setAll({
      fontSize: 14,
    });

    legend.valueLabels.template.setAll({
      fontSize: 14,
      marginLeft: 5,
    });

    legend.data.setAll(series.dataItems);

    // Add animation
    series.appear();

    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      mediaSourceTypeMentions: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);
    return () => {
      root.dispose();
    };
  }, [
    formattedData,
  ]);

  return (
    <ChartContainer
      chartId="chartdiv3"
      title="Media Source Type - Mentions"
      chartStyles={{
        height: `250px`,
      }}
      error={error}
    />
  );
};

export default MediaSourceTypeMentionsChart;
