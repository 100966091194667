import {
  TextField, Typography, Button,
} from '@mui/material';
import { Box } from '@mui/system';
import { isAppErrorVar } from 'apollo/cache';
import { ChangeEvent, useState } from 'react';

const WatchlistInput = () => {
  const [input, setInput] = useState<any>(undefined);
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (/[0-9]/.test(e.target.value) || /^(?![\s\S])/.test(e.target.value)) {
      setInput(e.target.value);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    isAppErrorVar(false);
    window.location.replace(`${window.location.origin}/${input}/analytics`);
  };
  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `space-between`,
      }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Typography
        sx={{
          fontFamily: `Nunito`,
          fontSize: `18px`,
          textAlign: `center`,
          padding: 3,
        }}
        textAlign="center"
      >
        Enter your watchlist ID
      </Typography>
      <TextField
        autoFocus
        type="text"
        value={input ?? ``}
        onChange={handleChange}
        variant="standard"
        sx={{ input: { textAlign: `center` } }}
      />
      <br />
      <Button
        disabled={!input}
        sx={{ padding: `2 3` }}
        type="submit"
        variant="contained"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Box>
  );
};

export default WatchlistInput;
