import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { timezoneVar } from 'apollo/cache';
import { GetUserDocument } from '../graphql/generated';

const useUser = () => {
  const [getUser, { data, loading: queryLoading, error }] = useLazyQuery(
    GetUserDocument,
    {
      // eslint-disable-next-line no-shadow
      onError: (error) => {
        // TODO: Handle individual errors i.e. not found etc.
        // When individual errors are handled, remove eslint-disable from above
        throw new Error(
          `An error has occurred while attempting to retrieve information for user: ${error}`,
        );
      },
    },
  );

  useEffect(() => {
    if (!data) {
      getUser();
      timezoneVar(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  }, [data]);

  const userInfo = {
    firstName: data?.me?.firstName,
    lastName: data?.me?.lastName,
    email: data?.me?.email,
    roles: data?.me?.roles,
    rootFolder: data?.me?.rootFolder,
  };
  const orgInfo = {
    orgName: data?.me?.organization?.name,
    orgId: data?.me?.organization?.id,
    orgData: data?.me?.organization?.jsondata,
    rootFolder: data?.me?.organization?.rootFolder,
  };

  return {
    userInfo,
    orgInfo,
    loading: queryLoading || (!userInfo && !orgInfo),
    error,
  };
};

export default useUser;
