/* eslint-disable react/require-default-props */
import { Card, CardContent, Typography } from '@mui/material';
import '@fontsource/nunito/500.css';
import '@fontsource/dosis/500.css';

interface Props {
  title: string
  value: number
  color: string
  secondaryValue?: string | null
}

const AnalyticsCard = ({
  title,
  value,
  secondaryValue,
  color,
}: Props) => (
  <Card sx={{
    backgroundColor: color,
    borderRadius: 0,
  }}
  >
    <CardContent>
      <Typography
        sx={{
          fontFamily: `Dosis`,
          textTransform: `uppercase`,
          color: `#FFF`,
          fontSize: `16px`,
          marginBottom: `10px`,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontFamily: `Nunito`,
          color: `#FFF`,
          fontSize: `30px`,
        }}
      >
        {value}
      </Typography>
      {secondaryValue
        && (
          <Typography
            sx={{
              fontFamily: `Nunito`,
              color: `#FFF`,
              fontSize: `12px`,
            }}
          >
            {`${secondaryValue}`}
          </Typography>
        )}
    </CardContent>
  </Card>
);

export default AnalyticsCard;
