import { render } from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {
  ApolloProvider,
} from '@apollo/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import apolloClient from './apollo/client';

import 'assets/styles/base.css';

Sentry.init({
  dsn: `https://5f8e398ab0d04cbe810ab6ae98665462@o57583.ingest.sentry.io/6275974`,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_APPLICATION_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

render(
  <ApolloProvider client={apolloClient}>
    <CssBaseline>
      <Router>
        <App />
      </Router>
    </CssBaseline>
  </ApolloProvider>,
  document.getElementById(`root`),
);
