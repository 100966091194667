import { ApolloClient, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { cache } from './cache';

// https://www.apollographql.com/docs/react/networking/authentication/
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_VERITONE_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get(process.env.REACT_APP_TOKEN_COOKIE);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ``,
    },
  };
});

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache,
  connectToDevTools: true,
});

export default client;
