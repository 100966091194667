import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { SearchMentionsDocument } from '../graphql/generated';

interface SubAggregate {
  operator: string;
  field: string;
  format?: string;
  timezone?: string;
  interval?: string;
}

interface Aggregate {
  name?: string;
  field: string;
  operator?: string;
  type?: string;
  timezone?: string;
  limit?: number;
  aggregate?: SubAggregate[];
}

interface Props {
  watchlistId: string | undefined;
  aggregate: Aggregate[];
}

const useAnalytics = ({ watchlistId, aggregate }: Props) => {
  const [
    getMentions,
    {
      data,
      error,
      loading,
    },
  ] = useLazyQuery(
    SearchMentionsDocument,
    {
      variables: {
        search: {
          index: [`mine`],
          query: {
            operator: `and`,
            conditions: [
              {
                operator: `terms`,
                field: `mentionStatusId`,
                values: [`1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`, `10`, `11`],
              },
              {
                operator: `terms`,
                field: `trackingUnitId`,
                values: [{ watchlistId }],
              },
              {
                operator: `or`,
                conditions: [
                  {
                    operator: `range`,
                    field: `hourOfDay`,
                    gte: 6,
                    lte: 23,
                  },
                  {
                    operator: `range`,
                    field: `hourOfDay`,
                    gte: 0,
                    lte: 5,
                  },
                ],
              },
            ],
          },
          aggregate,
        },
      },
    },
  );

  useEffect(() => {
    if (watchlistId !== ``) {
      getMentions();
    }
  }, [getMentions, watchlistId]);

  return {
    searchMentions: data?.searchMentions,
    error,
    loading,
  };
};

export default useAnalytics;
