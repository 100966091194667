import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import '@fontsource/dosis/500.css';

const ProcessingIndicator = () => (
  <Box>
    <Typography
      sx={{
        textAlign: `center`,
        color: `#7D7D7D`,
        fontFamily: `Dosis, sans-serif`,
        fontStyle: `italic`,
        paddingBottom: 1,
      }}
    >
      Your watchlist is being processed, please check back in a couple minutes
    </Typography>
  </Box>
);

export default ProcessingIndicator;
