import { useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import ChartContainer from 'features/Charts/components/ChartContainer';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import useWatchlist from 'hooks/useWatchlist';
import useAnalytics from 'hooks/useAnalytics';
import { timezoneVar, chartsLoadingStatusVar } from 'apollo/cache';

type DatesMentionsType = {
  doc_count: number,
  key: number,
  key_as_string: string,
}

type FormattedDataType = {
  category: string,
  value1: number,
}

const DatesMentionsChart = () => {
  const { watchlistId } = useParams();

  const {
    watchlist,
    loading: loadingWatchlist,
    error: errorWatchlist,
  } = useWatchlist(watchlistId || ``);

  const {
    searchMentions,
    loading: loadingMentions,
    error: errorMentions,
  } = useAnalytics({
    watchlistId,
    aggregate: [
      {
        name: `trackingUnitTop6Mentions`,
        field: `trackingUnitName`,
        operator: `term`,
        limit: 6,
        aggregate: [
          {
            operator: `date`,
            field: `mentionDate`,
            format: `MM/dd`,
            timezone: timezoneVar(),
            interval: `1d`,
          },
        ],
      },
    ],
  });

  const isLoading = useMemo(
    () => loadingWatchlist || !watchlist || !searchMentions || loadingMentions,
    [loadingWatchlist, watchlist, searchMentions, loadingMentions],
  );
  const watchlistName = watchlist?.name;
  const mentionsData: DatesMentionsType[] = searchMentions?.jsondata.aggregations.trackingUnitTop6Mentions.buckets[0]?.mentionDate.buckets || [];

  const formattedData: FormattedDataType[] = mentionsData.map((mention: DatesMentionsType) => ({
    category: mention.key_as_string,
    value1: mention.doc_count,
  }));

  // Instantiate chart
  useLayoutEffect(() => {
    const root = am5.Root.new(`chartdiv2`);

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    setDataIndicator(formattedData, root, isLoading);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    const yRenderer = yAxis.get(`renderer`);
    yRenderer.ticks.template.setAll({
      minPosition: 0.1,
      visible: true,
    });
    yRenderer.labels.template.setAll({
      minPosition: 0.1,
    });

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: `category`,
      }),
    );
    xAxis.data.setAll(formattedData);
    xAxis.set(`tooltip`, am5.Tooltip.new(root, {
      themeTags: [`axis`],
    }));

    // Create series
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: `Series`,
        xAxis,
        yAxis,
        valueYField: `value1`,
        categoryXField: `category`,
        fill: am5.color(0x638DD7),
        stroke: am5.color(0x638DD7),
        tooltip: am5.Tooltip.new(root, {
          labelText: `${watchlistName}: {valueY}`,
        }),
      }),
    );

    series.strokes.template.setAll({
      strokeWidth: 2,
    });

    series.data.setAll(formattedData);

    // Add animation
    series.appear();

    // Add cursor
    chart.set(`cursor`, am5xy.XYCursor.new(root, {}));

    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      datesMentions: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);
    return () => {
      root.dispose();
    };
  }, [
    formattedData,
    watchlistName,
  ]);

  return (
    <ChartContainer
      chartId="chartdiv2"
      title="Dates - Mentions"
      chartStyles={{
        height: `250px`,
      }}
      error={errorWatchlist || errorMentions}
    />
  );
};

export default DatesMentionsChart;
