import { useParams } from 'react-router';
import { useLayoutEffect } from 'react';
import { isTAFilteredVar, targetAudienceVar, chartsLoadingStatusVar } from 'apollo/cache';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import useNielsenArbitronAnalytics from 'hooks/useNielsenArbitronAnalytics';
import ChartContainer from 'features/Charts/components/ChartContainer';
import { createAudienceByAge } from '../../AudienceCharts/helpers';
import { audienceByAgeAggregate, targetedAggregate } from '../constants';

type AudienceType = {
  value: number
}

type AudienceByAgeType = {
  key: string,
  doc_count: number,
  audience: AudienceType,
}

type FormattedDataType = {
  category: string,
  value: number,
}

const AudienceByAgeNielsen = () => {
  const { watchlistId } = useParams();
  // check if WL is target audience filtered to supply query payload
  const { searchMentions, loading, error } = useNielsenArbitronAnalytics(
    isTAFilteredVar()
      ? {
        watchlistId,
        aggregate: targetedAggregate,
      }
      : {
        watchlistId,
        aggregate: audienceByAgeAggregate,
      },
  );
  // check if WL is target audience filtered to extract data buckets
  const audienceByAge = isTAFilteredVar()
    ? createAudienceByAge(
      searchMentions?.jsondata.aggregations.audienceByAge.buckets,
      targetAudienceVar(),
    )
    : searchMentions?.jsondata.aggregations.audienceByAge.buckets;

  const isLoading = !audienceByAge || loading;

  // Instantiate Chart
  useLayoutEffect(() => {
    // Define data
    const formattedData: FormattedDataType[] = isLoading
      ? []
      : audienceByAge.map((audienceGroup: AudienceByAgeType) => ({
        category: audienceGroup.key,
        value: audienceGroup.audience.value,
      }));

    const root = am5.Root.new(`chartdiv15`);

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    setDataIndicator(formattedData, root, isLoading);

    // Create chart
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: `panX`,
      wheelY: `zoomX`,
      layout: root.verticalLayout,
    }));

    // Create axes
    const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: `category`,
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance: 5,
      }),
    }));

    yAxis.data.setAll(formattedData);

    yAxis.get(`renderer`).labels.template.setAll({
      maxWidth: 80,
      fontSize: 12,
    });

    const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {}),
      min: 0,
    }));

    // Add series
    function createSeries(field: string, name: string) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name,
        xAxis,
        yAxis,
        valueXField: field,
        categoryYField: `category`,
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: `horizontal`,
          labelText: `{categoryY}: {valueX}`,
        }),
      }));

      series.columns.template.setAll({
        height: am5.p100,
        cornerRadiusTR: 5,
        cornerRadiusBR: 5,
      });

      series.data.setAll(formattedData);
      series.appear();

      return series;
    }

    createSeries(`value`, `Value`);

    // Add cursor
    const cursor = chart.set(`cursor`, am5xy.XYCursor.new(root, {
      behavior: `zoomY`,
    }));
    cursor.lineY.set(`forceHidden`, true);
    cursor.lineX.set(`forceHidden`, true);

    // Animate chart on load
    chart.appear(1000, 100);

    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      audienceByAge: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);

    return () => {
      root.dispose();
    };
  }, [
    isLoading,
    audienceByAge,
  ]);

  return (
    <ChartContainer
      chartId="chartdiv15"
      title="Audience by Age - Nielsen"
      chartStyles={{
        height: `275px`,
      }}
      error={error}
    />
  );
};

export default AudienceByAgeNielsen;
