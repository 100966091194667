export const formatDate = (date: any, isYesterday: boolean) => {
  const dd = String(date.getDate());
  const yesterday = String(date.getDate() - 1);
  const mm = String(date.getMonth() + 1);
  const yyyy = date.getFullYear();

  if (isYesterday) {
    return `${mm}/${yesterday}/${yyyy}`;
  }

  return `${mm}/${dd}/${yyyy}`;
};

export default formatDate;
