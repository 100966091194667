import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { authTokenVar, isAuthenticatedVar } from 'apollo/cache';
import Cookies from 'js-cookie';

type UserResponse = {
  token: string,
}

const useAuthentication = () => {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);

  useEffect(() => {
    if (!isAuthenticated) {
      fetch(`${process.env.REACT_APP_VERITONE_V1_API_URL}`, {
        credentials: `include`,
      }).then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        if (res.status >= 500) {
          throw new Error(`Could not connect to v1 API!`);
        }

        return window.location.replace(`${process.env.REACT_APP_VERITONE_OATH_LANDING_PAGE}/#?redirect=${window.location.href}`);
      }).then((payload: UserResponse) => {
        Cookies.set(process.env.REACT_APP_TOKEN_COOKIE, payload.token);
        authTokenVar(payload.token);
        isAuthenticatedVar(true);
      }).catch((error) => {
        throw new Error(`Error while attempting initial auth check. ${error}`);
      });
    }
  }, [isAuthenticated]);
};

export default useAuthentication;
