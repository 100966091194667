import { useParams } from 'react-router';
import { useLayoutEffect } from 'react';
import { isTAFilteredVar, chartsLoadingStatusVar } from 'apollo/cache';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import useNielsenArbitronAnalytics from 'hooks/useNielsenArbitronAnalytics';
import ChartContainer from 'features/Charts/components/ChartContainer';

export interface ImpressionType {
  value: number,
}

export interface ProgramsNielsenImpressionsType {
  doc_count: number,
  impressions: ImpressionType,
  key: string,
}

export interface FormattedDataType {
  category: string,
  value: number,
}

const ProgramsNielsenImpressions = () => {
  const { watchlistId } = useParams();
  const { searchMentions, loading, error } = useNielsenArbitronAnalytics({
    watchlistId,
    aggregate: [
      {
        name: `programTop6`,
        field: `programName.raw`,
        operator: `term`,
        limit: 12,
        aggregate: [
          {
            operator: `sum`,
            field: isTAFilteredVar()
              ? `targetAudience.impressions`
              : `impressions`,
            name: `impressions`,
          },
        ],
      },
    ],
  });

  const programsNielsenImpressions = searchMentions?.jsondata.aggregations.programTop6.buckets;

  const isLoading = !programsNielsenImpressions || loading;

  // Instantiate chart
  useLayoutEffect(() => {
    // Define data
    const formattedData: FormattedDataType[] = isLoading ? [] : programsNielsenImpressions.map((program: ProgramsNielsenImpressionsType) => ({
      category: program.key,
      value: program.impressions.value,
    }));

    const root = am5.Root.new(`chartdiv12`);

    setDataIndicator(formattedData, root, isLoading);

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    // Create chart
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: `panX`,
      wheelY: `zoomX`,
      layout: root.verticalLayout,
    }));

    // Create axes
    const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: `category`,
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance: 5,
      }),
    }));

    yAxis.data.setAll(formattedData);

    yAxis.get(`renderer`).labels.template.setAll({
      oversizedBehavior: `truncate`,
      maxWidth: 80,
      fontSize: 10,
    });

    const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {}),
      min: 0,
    }));

    // Add series
    function createSeries(field: string, name: string) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name,
        xAxis,
        yAxis,
        valueXField: field,
        categoryYField: `category`,
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: `horizontal`,
          labelText: `{categoryY}: {valueX}`,
        }),
      }));

      series.columns.template.setAll({
        height: am5.p100,
        cornerRadiusTR: 5,
        cornerRadiusBR: 5,
      });

      series.data.setAll(formattedData);
      series.appear();

      return series;
    }

    createSeries(`value`, `Value`);

    // Add cursor
    const cursor = chart.set(`cursor`, am5xy.XYCursor.new(root, {
      behavior: `zoomY`,
    }));
    cursor.lineY.set(`forceHidden`, true);
    cursor.lineX.set(`forceHidden`, true);

    // Animate chart on load
    chart.appear(1000, 100);
    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      programsImpressions: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);

    return () => {
      root.dispose();
    };
  }, [
    isLoading,
    programsNielsenImpressions,
  ]);

  return (
    <ChartContainer
      chartId="chartdiv12"
      title="Programs - Nielsen Impressions"
      chartStyles={{
        height: `275px`,
      }}
      error={error}
    />
  );
};

export default ProgramsNielsenImpressions;
