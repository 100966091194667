import { useMutation, useReactiveVar } from "@apollo/client";
import { authTokenVar } from "apollo/cache";
import { UserLogoutDocument } from "graphql/generated";

const useLogout = () => {
  const token = useReactiveVar(authTokenVar);
  const [logout] = useMutation(UserLogoutDocument, {
    variables: {
      token,
    },
  });

  return {
    logout,
  };
};

export default useLogout;
