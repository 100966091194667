/* eslint-disable no-unused-vars */
import React from 'react';
import '@fontsource/nunito/500.css';
import { Box } from '@mui/system';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FilterButton from 'features/FilterBar/components/FilterButton';
// import CreateDashboardButton from 'features/FilterBar/components/CreateDashboardButton';

// eslint-disable-next-line no-shadow
export enum FilterOptionsType {
  OVERVIEW,
  MENTIONS,
  NIELSEN_IMPRESSIONS,
  NUMERIS_IMPRESSIONS,
}

interface FilterBarProps {
  filterSelection: FilterOptionsType;
  onChange: (option: FilterOptionsType) => void;
  orgData: any;
}

const FilterBar = ({
  filterSelection,
  onChange,
  orgData,
}: FilterBarProps) => {
  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: FilterOptionsType) => {
    onChange(value);
  };

  const hasDataSource: boolean = orgData?.dataSources.length > 0;

  const isNielsenUser = orgData?.dataSources.includes(`arbitron`);

  return (
    <Box sx={{
      height: `55px`,
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
      background: `#fafafa`,
      borderTop: `1px solid #ccccad`,
      boxShadow: `0 2px 2px 0 rgba(0, 0, 0, 0.09), 0 4px 3px 0 rgba(0, 0, 0, 0.08)`,
    }}
    >
      <ToggleButtonGroup
        sx={{
          width: `427px`,
          height: `35px`,
          marginLeft: `25px`,
        }}
        exclusive
        value={filterSelection}
        onChange={handleChange}
      >
        <FilterButton
          value={FilterOptionsType.OVERVIEW}
          title="Overview"
        />
        <FilterButton
          value={FilterOptionsType.MENTIONS}
          title="Mentions"
        />
        {hasDataSource
        && (
          <FilterButton
            value={isNielsenUser ? FilterOptionsType.NIELSEN_IMPRESSIONS : FilterOptionsType.NUMERIS_IMPRESSIONS}
            title={isNielsenUser ? `Nielsen Impressions` : `Numeris Impressions`}
          />
        )}
      </ToggleButtonGroup>
      {/* <Box sx={{
        width: `217px`,
      }}
      >
        <CreateDashboardButton />
      </Box> */}
    </Box>
  );
};

export default FilterBar;
