import { Box } from '@mui/system';
import { Link } from '@mui/material';
import { watchlistIdVar } from 'apollo/cache';

const NoAccessInOrg = () => (
  <Box>
    Insufficient permissions to view watchlist ID
    {` `}
    {watchlistIdVar()}
    .
    Please check the ID or navigate to another watchlist in this organization, or reach out to
    {` `}
    <Link sx={{ textDecoration: `none`, color: `#2196F3` }} href="mailto:support@veritone.com">support@veritone.com</Link>
    {` `}
    for further assistance.
  </Box>
);

export default NoAccessInOrg;
