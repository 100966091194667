import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import WatchlistInput from './WatchlistInput';

const SplashPage = () => (
  <Box
    sx={{
      display: `grid`,
      justifyContent: `center`,
      alignContent: `center`,
      padding: 2,
      height: `90%`,
    }}
  >
    <Typography
      variant="h5"
      textAlign="center"
      sx={{
        fontFamily: `Nunito`,
        fontSize: `20px`,
        margin: `10px 0`,
        textAlign: `center`,
      }}
    >
      Welcome to Deep Link Watchlist App. You have come here without a valid
      link.
    </Typography>
    <WatchlistInput />
  </Box>
);

export default SplashPage;
