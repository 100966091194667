import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { isTAFilteredVar, targetAudienceVar } from 'apollo/cache';
import { useEffect } from 'react';
import { GetWatchlistDocument } from '../graphql/generated';

const useWatchlist = (id: string) => {
  const isTAFiltered = useReactiveVar(isTAFilteredVar);
  const [
    getWatchlist, {
      data,
      loading: queryLoading,
      error,
    },
  ] = useLazyQuery(GetWatchlistDocument, {
    variables: { watchlistId: id },
    // eslint-disable-next-line no-shadow
    onError: (error) => {
      // TODO: Handle individual errors i.e. not found etc.
      // When individual errors are handled, remove eslint-disable from above
      throw new Error(`An error has occurred while attempting retrieve a watchlist with an id of ${id}. ${error.message}`);
    },
    onCompleted: ({ watchlist }) => {
      if (watchlist?.details?.targetAudience?.gender) {
        isTAFilteredVar(true);
        targetAudienceVar(watchlist?.details?.targetAudience);
      }
    },
  });

  useEffect(() => {
    if (id !== ``) {
      getWatchlist();
    }
  }, [getWatchlist, id, isTAFiltered]);

  const watchlist = data?.watchlist;

  return {
    getWatchlist,
    watchlist,
    loading: queryLoading || !watchlist,
    error,
  };
};

export default useWatchlist;
