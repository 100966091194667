export const audienceByAgeAggregate = [
  {
    name: `audienceByAge`,
    field: `audience.age_group`,
    operator: `term`,
    aggregate: [
      {
        name: `audience`,
        field: `audience.audience`,
        operator: `sum`,
      },
    ],
  },
];

export const audienceByGenderAggregate = [
  {
    name: `audienceByGender`,
    field: `audience.gender`,
    operator: `term`,
    aggregate: [
      {
        name: `audience`,
        field: `audience.audience`,
        operator: `sum`,
      },
    ],
  },
];

export const targetedAggregate = [
  {
    name: `audienceByAge`,
    field: `audience.age_group`,
    operator: `term`,
    aggregate: [
      {
        name: `audience`,
        field: `audience.audience`,
        operator: `sum`,
      },
      {
        name: `gender`,
        field: `audience.gender`,
        operator: `term`,
        aggregate: [
          {
            name: `audience`,
            field: `audience.audience`,
            operator: `sum`,
          },
        ],
      },
    ],
  },
];
