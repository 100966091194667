const dayOfWeekMapping: any = {
  SUNDAY: `Sun`,
  MONDAY: `Mon`,
  TUESDAY: `Tue`,
  WEDNESDAY: `Wed`,
  THURSDAY: `Thu`,
  FRIDAY: `Fri`,
  SATURDAY: `Sat`,
};

export default dayOfWeekMapping;
