import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import FilterBar, { FilterOptionsType } from 'features/FilterBar';
import DayOfWeekMentionsChart from 'features/Charts/Mentions/DayOfWeekMentionsChart';
import DatesMentionsChart from 'features/Charts/Mentions/DatesMentionsChart';
import MediaSourceTypeMentionsChart from 'features/Charts/Mentions/MediaSourceTypeMentionsChart';
import TopProgramsByMentionsCountChart from 'features/Charts/Mentions/TopProgramsByMentionsCountChart';
import TopMentionsSourceTypeByMentionsCountChart from 'features/Charts/Mentions/TopMentionsSourceTypeByMentionsCountChart';
import LabelsCountByTypeMentionsChart from 'features/Charts/Mentions/LabelsCountByTypeMentionsChart';
import LabelsCountByDateMentionsChart from 'features/Charts/Mentions/LabelsCountByDateMentionsChart';
import EarnedAndPaidByDate from 'features/Charts/Mentions/EarnedAndPaidMentions';
import DatesImpressions from 'features/Charts/AudienceCharts/DatesImpressions';
import DayOfWeekNielsenImpressions from 'features/Charts/Nielsen/DayOfWeekNielsenImpressions';
import LabelsNielsenImpressionsByType from 'features/Charts/Nielsen/LabelsNielsenImpressionsByType';
import LabelsNielsenImpressionsByDate from 'features/Charts/Nielsen/LabelsNielsenImpressionsByDate';
import ProgramsNielsenImpressions from 'features/Charts/Nielsen/ProgramsNielsenImpressions';
import MediaSourceTypeNielsen from 'features/Charts/Nielsen/MediaSourceTypeNielsen';
import AudienceByAgeNielsen from 'features/Charts/Nielsen/AudienceByAgeNielsen';
import AudienceByGenderNielsen from 'features/Charts/Nielsen/AudienceByGenderNielsen';
import EarnedAndPaidImpressions from 'features/Charts/AudienceCharts/EarnedAndPaidImpressions';
// import TabBar from 'features/TabBar';
import GroupedChartsContainer from 'features/Charts/components/GroupedChartsContainer';
import TitleBar from 'features/TitleBar';
import useWatchlist from 'hooks/useWatchlist';
import ProcessingIndicator from 'features/ProcessingIndicator';
import LoadingIndicator from 'features/LoadingIndicator';
import ErrorPage from 'features/ErrorPage';
import DescriptionBox from 'features/FilterBar/components/DescriptionBox';
import useUser from 'hooks/useUser';
import useWLFolder from 'hooks/useWLFolder';
import statusAvailability from 'features/Charts/helpers/statusAvailability';
import throwAppError from 'utils/throwAppError';
import { watchlistIdVar } from 'apollo/cache';
import { watchlistNotFound, noAccessInOrg } from 'features/ErrorPage/constants';

const Analytics = () => {
  const { watchlistId } = useParams();
  watchlistIdVar(watchlistId);
  const { watchlist, loading, error } = useWatchlist(watchlistId || ``);
  const isUpdating = watchlist?.isUpdating;
  const {
    userInfo: { rootFolder: userFolder },
    orgInfo: { orgData, rootFolder: orgFolder },
  } = useUser();
  const [filterOption, setFilterOption] = useState<FilterOptionsType>(
    FilterOptionsType.OVERVIEW,
  );
  const { isStatusIncluded: isPaidStatusIncluded } = statusAvailability(8);
  const { isStatusIncluded: isEarnedStatusIncluded } = statusAvailability(9);

  const impressionsDescription = `View your media’s audience reach by date, day of the week, program, source type, age demographics`;

  const [title, description, charts] = useMemo(() => {
    switch (filterOption) {
      case FilterOptionsType.OVERVIEW:
        return [
          `Overview`,
          `View basic Watchlist and Mention analytics data`,
          <GroupedChartsContainer>
            <DatesMentionsChart />
            <DayOfWeekMentionsChart />
            <DatesImpressions />
            <DayOfWeekNielsenImpressions />
            <MediaSourceTypeMentionsChart />
          </GroupedChartsContainer>,
        ];
      case FilterOptionsType.MENTIONS:
        return [
          `Mentions`,
          `View comprehensive mention analytics data based on occurrence by date, source or program`,
          <GroupedChartsContainer>
            <DatesMentionsChart />
            <DayOfWeekMentionsChart />
            <TopProgramsByMentionsCountChart />
            <TopMentionsSourceTypeByMentionsCountChart />
            <LabelsCountByTypeMentionsChart />
            <LabelsCountByDateMentionsChart />
            <MediaSourceTypeMentionsChart />
            {(isPaidStatusIncluded || isEarnedStatusIncluded)
            && <EarnedAndPaidByDate />}
          </GroupedChartsContainer>,
        ];
      case FilterOptionsType.NIELSEN_IMPRESSIONS:
        return [
          `Nielsen Impressions`,
          impressionsDescription,
          <GroupedChartsContainer>
            <DatesImpressions />
            <DayOfWeekNielsenImpressions />
            <LabelsNielsenImpressionsByType />
            <LabelsNielsenImpressionsByDate />
            <ProgramsNielsenImpressions />
            <MediaSourceTypeNielsen />
            <AudienceByAgeNielsen />
            <AudienceByGenderNielsen />
            {(isPaidStatusIncluded || isEarnedStatusIncluded)
            && <EarnedAndPaidImpressions />}
          </GroupedChartsContainer>,
        ];
      case FilterOptionsType.NUMERIS_IMPRESSIONS:
        return [
          `Numeris Impressions`,
          impressionsDescription,
          <GroupedChartsContainer>
            <DatesImpressions />
            <DayOfWeekNielsenImpressions />
            <LabelsNielsenImpressionsByType />
            <LabelsNielsenImpressionsByDate />
            <ProgramsNielsenImpressions />
            <MediaSourceTypeNielsen />
            <AudienceByAgeNielsen />
            <AudienceByGenderNielsen />
            {isPaidStatusIncluded && <EarnedAndPaidImpressions />}
          </GroupedChartsContainer>,
        ];
      default:
        return [
          `Overview`,
          `View basic Watchlist and Mention analytics data`,
          <GroupedChartsContainer>
            <DatesMentionsChart />
            <DayOfWeekMentionsChart />
            <DatesImpressions />
            <DayOfWeekNielsenImpressions />
            <MediaSourceTypeMentionsChart />
          </GroupedChartsContainer>,
        ];
    }
  }, [filterOption]);

  if (error) {
    if (
      error.graphQLErrors[0].message === `The requested object was not found`
    ) {
      throwAppError(watchlistNotFound);
    }
    return <ErrorPage />;
  }

  if (loading || !watchlist || !userFolder || !orgFolder) {
    return <LoadingIndicator text="Retrieving watchlist..." />;
  }

  const {
    name, folders, startDateTime, stopDateTime,
  } = watchlist;

  if (!folders || !name) {
    return <LoadingIndicator text="Loading data..." />;
  }

  const { canAccessWL, wlFolderPath } = useWLFolder({
    userFolderId: userFolder?.id,
    orgFolderId: orgFolder?.id,
    wlFolderData: folders && folders[0],
  });

  if (!canAccessWL) {
    throwAppError(noAccessInOrg);
  } return (
    <div>
      <TitleBar title={name} path={wlFolderPath} />
      {/* <TabBar /> */}
      {
        isUpdating && <ProcessingIndicator />
      }
      <FilterBar
        filterSelection={filterOption}
        onChange={setFilterOption}
        orgData={orgData}
      />
      <DescriptionBox title={title} description={description} name={name} startDateTime={startDateTime} stopDateTime={stopDateTime} />
      <div>
        {charts || []}
      </div>
    </div>
  );
};

export default Analytics;
