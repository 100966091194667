import { useLayoutEffect, useMemo } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useParams } from 'react-router';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import useAnalytics from 'hooks/useAnalytics';
import ChartContainer from 'features/Charts/components/ChartContainer';
import defineVerificationStatus from 'features/Charts/helpers/defineVerificationStatus';
import { timezoneVar, chartsLoadingStatusVar } from 'apollo/cache';

type LabelsCountByDate = {
  key_as_string: string,
  key: number,
  doc_count: number,
}

type FormattedDataType = {
  category: string,
  pendingVerification?: number,
  verified?: number,
  needsReview?: number,
  requestBonus?: number,
  invalid?: number,
  processingVerification?: number,
  autoVerified?: number,
  paid?: number,
  earned?: number,
  loggedSpots?: number,
  liveChatter?: number,
  unknownStatus?: number,
}

const LabelsCountByDateMentionsChart = () => {
  const { watchlistId } = useParams();
  const {
    searchMentions,
    loading,
    error,
  } = useAnalytics({
    watchlistId,
    aggregate: [
      {
        name: `verificationStatus`,
        field: `mentionStatusId`,
        operator: `term`,
        aggregate: [
          {
            field: `mentionDate`,
            operator: `date`,
            format: `MM/dd`,
            timezone: timezoneVar(),
            interval: `1d`,
          },
        ],
      },
    ],
  });

  const labelsCountMentionsData = useMemo(() => searchMentions?.jsondata.aggregations.verificationStatus.buckets, [searchMentions]);

  const labeledData: any[] = defineVerificationStatus((labelsCountMentionsData || []), `mentionsByDate`);

  const isLoading = !labeledData || loading;

  // Instantiate chart
  useLayoutEffect(() => {
    // Define Data
    const formattedChartData: FormattedDataType[] = !labeledData.length ? [] : labeledData[0].value.map((mention: LabelsCountByDate, index: number) => {
      let dataPoint = {};
      for (let i = 0; i < labeledData.length; i += 1) {
        dataPoint = {
          ...dataPoint,
          category: mention.key_as_string,
          [labeledData[i].key]: labeledData[i].value[index] ? labeledData[i].value[index].doc_count : 0,
        };
      }
      return dataPoint;
    });

    const root = am5.Root.new(`chartdiv7`);
    setDataIndicator(formattedChartData, root, isLoading);

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    const yRenderer = yAxis.get(`renderer`);
    yRenderer.ticks.template.setAll({
      minPosition: 0.1,
      visible: true,
    });
    yRenderer.labels.template.setAll({
      minPosition: 0.1,
    });

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: `category`,
      }),
    );
    xAxis.data.setAll(formattedChartData);
    xAxis.set(`tooltip`, am5.Tooltip.new(root, {
      themeTags: [`axis`],
    }));

    // Create series
    function createSeries(name: string, field: string) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name,
          xAxis,
          yAxis,
          valueYField: field,
          categoryXField: `category`,
          stacked: true,
          tooltip: am5.Tooltip.new(root, {
            labelText: `${name}: {valueY}`,
            minHeight: 20,
            scale: 0.70,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 6,
          }),
        }),
      );
      series.strokes.template.setAll({
        strokeWidth: 2,
      });
      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });
      series.data.setAll(formattedChartData);

      // Add animation
      series.appear();
    }

    // create all required series based on available data
    // reverse is used so series overlap properly
    labeledData.reverse().forEach((seriesType: any) => {
      const verificationLabel = Object.values(seriesType)[0];
      const verificationKey = Object.values(seriesType)[1];
      return createSeries(`${verificationLabel}`, `${verificationKey}`);
    });

    // Add cursor
    chart.set(`cursor`, am5xy.XYCursor.new(root, {}));
    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      labelsCountByDate: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);

    return () => {
      root.dispose();
    };
  }, [
    labeledData,
  ]);

  return (
    <ChartContainer
      chartId="chartdiv7"
      title="Labels Count by Date - Mentions"
      chartStyles={{
        height: `250px`,
      }}
      error={error}
    />
  );
};

export default LabelsCountByDateMentionsChart;
