import { Box } from '@mui/system';
import React from 'react';
import WatchlistAnalyticsCards from 'features/WatchlistAnalyticsCards';

const GroupedChartsContainer = ({ children }: any): JSX.Element => {
  const chunkSize = 4;
  let id = ``;
  let pageNumber = 1;
  const formattedChildren = [];

  for (let i = 0; i < children.length; i += chunkSize) {
    const chunk = children.slice(i, i + chunkSize);
    const hasChartToDisplay = !(chunk.length === 1 && chunk.includes(false));
    if (hasChartToDisplay) {
      id = `report-page-${pageNumber}`;
      formattedChildren.push(
        <div id={id} key={id}>
          {pageNumber === 1 && (
            <WatchlistAnalyticsCards />
          )}
          <Box
            sx={{
              display: `grid`,
              gridTemplateColumns: `1fr 1fr`,
            }}
          >
            {chunk}
          </Box>
        </div>,
      );
    }
    pageNumber += 1;
  }

  return (
    <Box>
      {formattedChildren}
    </Box>
  );
};

export default GroupedChartsContainer;
