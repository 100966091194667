import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { SearchMentionsDocument } from '../graphql/generated';

interface SubAggregate {
  operator: string;
  field: string;
  format?: string;
  timezone?: string;
  interval?: string;
}

interface Aggregate {
  name?: string;
  field: string;
  format?: string;
  operator?: string;
  type?: string;
  timezone?: string;
  limit?: number;
  interval?: string;
  aggregate?: SubAggregate[];
}

interface Props {
  watchlistId: string | undefined;
  aggregate: Aggregate[];
  targetAudience: boolean;
  mentionStatusId: string;
}

const useStatusMentions = ({
  watchlistId,
  aggregate,
  targetAudience,
  mentionStatusId,
}: Props) => {
  const conditions: any = [
    {
      operator: `terms`,
      field: `mentionStatusId`,
      values: [mentionStatusId],
    },
    {
      operator: `terms`,
      field: `trackingUnitId`,
      values: [{ watchlistId }],
    },
    {
      operator: `or`,
      conditions: [
        {
          operator: `range`,
          field: `hourOfDay`,
          gte: 6,
          lte: 23,
        },
        {
          operator: `range`,
          field: `hourOfDay`,
          gte: 0,
          lte: 5,
        },
      ],
    },
  ];

  if (targetAudience) {
    conditions.push({
      operator: `range`,
      field: `targetAudience.impressions`,
      gt: 0,
      lte: 8999999999990000000,
    });
  }

  const [getMentions, { data, error, loading }] = useLazyQuery(
    SearchMentionsDocument,
    {
      variables: {
        search: {
          index: [`mine`],
          query: {
            operator: `and`,
            conditions,
          },
          aggregate,
        },
      },
    },
  );

  useEffect(() => {
    if (watchlistId !== ``) {
      getMentions();
    }
  }, [getMentions, watchlistId]);

  const mentions = data?.searchMentions;
  return {
    mentions,
    mentionsError: error,
    mentionsLoading: loading,
  };
};

export default useStatusMentions;
