export const nielsenAnalyticsVariables = (
  watchlistId: string | undefined,
  aggregate: any[],
) => ({
  search: {
    index: [`mine`],
    query: {
      operator: `and`,
      conditions: [
        {
          operator: `terms`,
          field: `mentionStatusId`,
          values: [`1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`, `10`, `11`],
        },
        {
          operator: `terms`,
          field: `trackingUnitId`,
          values: [{ watchlistId }],
        },
        {
          operator: `or`,
          conditions: [
            {
              operator: `range`,
              field: `hourOfDay`,
              gte: 6,
              lte: 23,
            },
            {
              operator: `range`,
              field: `hourOfDay`,
              gte: 0,
              lte: 5,
            },
          ],
        },
        {
          operator: `or`,
          conditions: [
            {
              operator: `term`,
              field: `metadata.audienceProvider`,
              value: `Nielsen`,
            },
            {
              operator: `exists`,
              name: `metadata.audienceProvider`,
              not: true,
            },
          ],
        },
      ],
    },
    aggregate,
  },
});

export const numerisAnalyticsVariables = (
  watchlistId: string | undefined,
  aggregate: any[],
) => ({
  search: {
    index: [`mine`],
    query: {
      operator: `and`,
      conditions: [
        {
          operator: `terms`,
          field: `mentionStatusId`,
          values: [`1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`, `10`, `11`],
        },
        {
          operator: `terms`,
          field: `trackingUnitId`,
          values: [{ watchlistId }],
        },
        {
          operator: `or`,
          conditions: [
            {
              operator: `range`,
              field: `hourOfDay`,
              gte: 6,
              lte: 23,
            },
            {
              operator: `range`,
              field: `hourOfDay`,
              gte: 0,
              lte: 5,
            },
          ],
        },
        {
          operator: `term`,
          field: `metadata.audienceProvider`,
          value: `Numeris`,
        },
        {
          operator: `range`,
          field: `targetAudience.impressions`,
          gt: 0,
          lte: 8999999999990000000,
        },
      ],
    },
    aggregate,
  },
});
