import {
  Routes,
  Route,
} from 'react-router-dom';
import { Box } from '@mui/system';
import { ErrorBoundary } from '@sentry/react';
import * as am5 from '@amcharts/amcharts5';
import { useReactiveVar } from '@apollo/client';
import { isAppErrorVar, isAuthenticatedVar } from 'apollo/cache';
import useLogout from 'hooks/useLogout';
import useAuthentication from 'hooks/useAuthentication';
import useErrorHandling from 'hooks/useErrorHandling';
import Header from '../features/Header';
import Analytics from '../pages/Analytics';
import ErrorPage from '../features/ErrorPage';
import SplashPage from '../features/SplashPage';
import initPendo from '../pendo';
// Add amCharts 5 license
am5.addLicense(`AM5C196223670`);
// Add amCharts 5: Maps license
am5.addLicense(`AM5M196223670`);

const App = () => {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  const isError = useReactiveVar(isAppErrorVar);
  const {
    logout,
  } = useLogout();
  useAuthentication();
  useErrorHandling();
  initPendo();

  if (!isAuthenticated) {
    return null;
  }

  const content = isError ? <ErrorPage /> : (
    <ErrorBoundary fallback={ErrorPage}>
      <Routes>
        <Route path=":watchlistId/analytics/" element={<Analytics />} />
        {/* <Route path=":watchlistId/mentions/" element={<Mentions />} /> */}
        <Route
          path="*"
          element={<SplashPage />}
        />
      </Routes>
    </ErrorBoundary>
  );

  return (
    <Box
      sx={{
        display: `grid`,
        gridTemplateRows: `min-content 1fr`,
        height: `100%`,
      }}
    >
      <Header
        logout={() => {
          logout().then(() => {
            isAuthenticatedVar(false);
          });
        }}
      />
      <main>{content}</main>
    </Box>
  );
};

export default App;
