export const weekDays = [
  `Sunday`,
  `Monday`,
  `Tuesday`,
  `Wednesday`,
  `Thursday`,
  `Friday`,
  `Saturday`,
] as const;

export const GENDER_KEYS = {
  1: `men`,
  2: `women`,
  3: `both`,
};

export const GENDER_VALUES = {
  men: 1,
  women: 2,
  both: 3,
};

export const NIELSEN_AGE_GROUPS = {
  0: `18-24`,
  1: `25-34`,
  2: `35-44`,
  3: `45-54`,
  4: `55-64`,
  5: `65+`,
};

export const NUMERIS_AGE_GROUPS = {
  0: `12-17`,
  1: `18-20`,
  2: `21-24`,
  3: `25-34`,
  4: `35-44`,
  5: `45-49`,
  6: `50-54`,
  7: `55-64`,
  8: `65+`,
};

export const AUDIENCE_PROVIDER = {
  NIELSEN: `Nielsen`,
  NUMERIS: `Numeris`,
};

export const ACCEPTABLE_AGE_VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export const MIN_AGE_GROUP = 0;
export const MAX_AGE_GROUP = 8;
