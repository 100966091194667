// import { BsInfoCircle, BsShare } from 'react-icons/bs';
import '@fontsource/dosis/500.css';
import '@fontsource/nunito/500.css';
import { Typography } from '@mui/material/';
import { Box } from '@mui/system';

interface TitleBarProps {
  title: string;
  path: string;
}

const TitleBar = ({
  title,
  path,
}: TitleBarProps): JSX.Element => (
  <Box sx={{ height: `100px` }}>
    <Box>
      <Box sx={{
        display: `flex`,
        margin: `20px 0 2px 20px`,
        alignItems: `center`,
      }}
      >
        <Typography sx={{
          fontFamily: `'Nunito', sans-serif`,
          fontSize: `24px`,
          fontWeight: `700`,
          marginRight: `16px`,
          color: `#2a323c`,
        }}
        >
          {title}
        </Typography>
        {/* <BsInfoCircle /> */}
      </Box>
      <Typography sx={{
        textTransform: `uppercase`,
        fontWeight: `500`,
        fontSize: `14px`,
        margin: `0 0 2px 20px`,
        fontFamily: `'Dosis', sans-serif`,
      }}
      >
        {path}
        {title}
      </Typography>
    </Box>
    <Box sx={{
      width: `71px`,
      position: `absolute`,
      right: `100px`,
      top: `84px`,
      padding: `6px 10px 6px 10px`,
    }}
    >
      {/* <Button
        variant="outlined"
        startIcon={<BsShare />}
      >
        Share
      </Button> */}
    </Box>
  </Box>
);

export default TitleBar;
