/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { ApolloError } from '@apollo/client';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CSSProperties } from 'react';
import '@fontsource/nunito/500.css';

type Props = {
  chartId: string
  title: string
  subTitle?: string
  chartStyles?: CSSProperties
  error: ApolloError | undefined
}

const ChartContainer = ({
  chartId,
  title,
  subTitle,
  chartStyles = {},
}: Props) => (
  <Box sx={{
    padding: 1,
  }}
  >
    <Typography sx={{
      fontFamily: `Nunito`,
      fontSize: `16px`,
      margin: `15px 0`,
      textAlign: `center`,
    }}
    >
      {title}
    </Typography>
    {subTitle
      && (
      <Typography sx={{
        fontFamily: `Nunito`,
        fontSize: `14px`,
        margin: `5px 0`,
        textAlign: `center`,
      }}
      >
        {subTitle}
      </Typography>
      )}
    <div
      id={chartId}
      style={{
        width: `100%`,
        ...chartStyles,
      }}
    />
  </Box>
);

export default ChartContainer;
