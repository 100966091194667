import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import useUser from 'hooks/useUser';
import { SearchMentionsDocument } from '../graphql/generated';
import { nielsenAnalyticsVariables, numerisAnalyticsVariables } from '../features/Charts/AudienceCharts/constants';

interface SubAggregate {
  name?: string;
  operator: string;
  field: string;
  format?: string;
  timezone?: string;
  interval?: string;
  aggregate?: SubAggregate[];
}

interface Aggregate {
  name?: string;
  field: string;
  operator?: string;
  type?: string;
  timezone?: string;
  limit?: number;
  sort?: {
    criteria: string;
    field: string;
    name?: string
  };
  aggregate?: SubAggregate[];
}

interface Props {
  watchlistId: string | undefined;
  aggregate: Aggregate[];
}

const useAudienceAnalytics = ({ watchlistId, aggregate }: Props) => {
  const {
    orgInfo: {
      orgData,
    },
  } = useUser();

  const isNielsenUser = orgData?.dataSources.includes(`arbitron`);
  const [
    getMentions,
    {
      data,
      error,
      loading,
    },
  ] = useLazyQuery(SearchMentionsDocument, {
    variables: isNielsenUser ? nielsenAnalyticsVariables(watchlistId, aggregate) : numerisAnalyticsVariables(watchlistId, aggregate),
  });

  useEffect(() => {
    if (watchlistId !== ``) {
      getMentions();
    }
  }, [getMentions, watchlistId]);

  return {
    searchMentions: data?.searchMentions,
    error,
    loading,
  };
};

export default useAudienceAnalytics;
