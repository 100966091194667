import { useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { isTAFilteredVar, timezoneVar, chartsLoadingStatusVar } from 'apollo/cache';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import useNielsenArbitronAnalytics from 'hooks/useNielsenArbitronAnalytics';
import ChartContainer from 'features/Charts/components/ChartContainer';
import dayOfWeekMapping from 'features/Charts/helpers/dayOfWeekMapping';

interface NielsenImpressionType {
  value: number,
}

interface DayOfWeekNielsenImpressionType {
  key: string,
  doc_count: number,
  impressions: NielsenImpressionType,
}

interface FormattedDataType {
  category: string,
  value: number,
}

const DayOfWeekNielsenImpressions = () => {
  const { watchlistId } = useParams();

  const { searchMentions, loading, error } = useNielsenArbitronAnalytics({
    watchlistId,
    aggregate: [
      {
        field: `mentionDate`,
        operator: `dateHistogram`,
        name: `dayOfWeek`,
        type: `dayOfWeek`,
        timezone: timezoneVar(),
        aggregate: [
          {
            operator: `sum`,
            field: isTAFilteredVar()
              ? `targetAudience.impressions`
              : `impressions`,
            name: `impressions`,
          },
        ],
      },
    ],
  });

  const dayOfWeekImpressions: any[] = searchMentions?.jsondata.aggregations.dayOfWeek.buckets;

  const isLoading = useMemo(
    () => !dayOfWeekImpressions || loading,
    [dayOfWeekImpressions, loading],
  );

  // Instantiate chart
  useLayoutEffect(() => {
    // Define data
    const formattedData: FormattedDataType[] = isLoading ? [] : dayOfWeekImpressions.map((dailyImpression: DayOfWeekNielsenImpressionType) => ({
      category: dayOfWeekMapping[dailyImpression.key],
      value: dailyImpression.impressions.value,
    }));

    const root = am5.Root.new(`chartdiv10`);

    setDataIndicator(formattedData, root, isLoading);

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    const yRenderer = yAxis.get(`renderer`);
    yRenderer.ticks.template.setAll({
      minPosition: 0.1,
      visible: true,
    });
    yRenderer.labels.template.setAll({
      minPosition: 0.1,
    });

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 5,
        }),
        categoryField: `category`,
      }),
    );
    xAxis.data.setAll(formattedData);
    xAxis.set(`tooltip`, am5.Tooltip.new(root, {
      themeTags: [`axis`],
    }));

    // Create series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: `Series`,
        xAxis,
        yAxis,
        valueYField: `value`,
        categoryXField: `category`,
        fill: am5.color(0x638DD7),
        stroke: am5.color(0x638DD7),
        tooltip: am5.Tooltip.new(root, {
          labelText: `{category}[/]{valueX.formatDate()}:\n {valueY}`,
        }),
      }),
    );
    series.data.setAll(formattedData);

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    });

    // Add animation
    series.appear();

    // Add cursor
    chart.set(`cursor`, am5xy.XYCursor.new(root, {}));

    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      dayOfWeekImpressions: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);
    return () => {
      root.dispose();
    };
  }, [
    isLoading,
    dayOfWeekImpressions,
  ]);

  return (
    <ChartContainer
      chartId="chartdiv10"
      title="Day of Week - Nielsen Impressions"
      chartStyles={{
        height: `275px`,
      }}
      error={error}
    />
  );
};

export default DayOfWeekNielsenImpressions;
