import '@fontsource/dosis/500.css';
import {
  Box,
  IconButton,
  Link,
} from '@mui/material';
import ExitIcon from '@mui/icons-material/ExitToApp';

interface Props {
  logout(): void
}

const Header = ({
  logout,
}: Props) => (
  <Box sx={{
    backgroundColor: `rgb(33, 150, 243)`,
    display: `flex`,
    justifyContent: `space-between`,
    alignContent: `center`,
    padding: 2,
  }}
  >
    <Link
      href={process.env.REACT_APP_LEGACY_DISCOVERY_URL}
      underline="none"
      target="_blank"
      rel="noopener"
      sx={{
        color: `#fff`,
        fontSize: `18px`,
        fontWeight: `400`,
        textTransform: `uppercase`,
        fontFamily: `'Dosis', sans-serif`,
      }}
    >
      Discovery
    </Link>
    <IconButton size="small" onClick={logout}>
      <ExitIcon htmlColor="white" />
    </IconButton>
  </Box>
);

export default Header;
