import { useLayoutEffect, useMemo } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { useParams } from 'react-router';
import setDataIndicator from 'features/Charts/helpers/setDataIndicator';
import ChartContainer from 'features/Charts/components/ChartContainer';
import useStatusAudience from 'hooks/useStatusAudience';
import useUser from 'hooks/useUser';
import { isTAFilteredVar, timezoneVar, chartsLoadingStatusVar } from 'apollo/cache';

type FormattedDataType = {
  category: string,
  paid?: number,
  earned?: number,
}

type ImpressionType = {
  value: number,
}

type LabelsCountByDate = {
  key_as_string: string,
  key: number,
  doc_count: number,
  dateImpressions: ImpressionType,
}

const EarnedAndPaidImpressions = () => {
  const { watchlistId } = useParams();

  const {
    orgInfo: {
      orgData,
    },
  } = useUser();

  const isNielsenUser = orgData?.dataSources.includes(`arbitron`);

  const { mentions: paidMentions, mentionsError: paidMentionsError } = useStatusAudience({
    watchlistId,
    aggregate: [
      {
        name: `preRecordedImpressionsByDate`,
        field: `mentionDate`,
        format: `MM/dd`,
        operator: `date`,
        timezone: timezoneVar(),
        interval: `1d`,
        aggregate: [
          {
            name: `dateImpressions`,
            field: isTAFilteredVar()
              ? `targetAudience.impressions`
              : `impressions`,
            operator: `sum`,
          },
        ],
      },
    ],
    targetAudience: isTAFilteredVar(),
    mentionStatusId: `8`,
  });

  const { mentions: earnedMentions, mentionsError: earnedMentionsError } = useStatusAudience({
    watchlistId,
    aggregate: [
      {
        name: `liveReadImpressionsByDate`,
        field: `mentionDate`,
        format: `MM/dd`,
        operator: `date`,
        timezone: timezoneVar(),
        interval: `1d`,
        aggregate: [
          {
            name: `dateImpressions`,
            field: isTAFilteredVar()
              ? `targetAudience.impressions`
              : `impressions`,
            operator: `sum`,
          },
        ],
      },
    ],
    targetAudience: isTAFilteredVar(),
    mentionStatusId: `9`,
  });

  const paidData = useMemo(() => paidMentions?.jsondata.aggregations.preRecordedImpressionsByDate.buckets, [paidMentions]) || [];
  const earnedData = useMemo(() => earnedMentions?.jsondata.aggregations.liveReadImpressionsByDate.buckets, [earnedMentions]) || [];
  const isLoading = !paidData;

  // Instantiate chart
  useLayoutEffect(() => {
    // Define Data
    let formattedData: FormattedDataType[] = [];
    if (paidData.length) {
      formattedData = paidData.map((mention: LabelsCountByDate, index: number) => {
        const dataPoint = {
          category: mention.key_as_string,
          [`paid`]: mention.dateImpressions.value,
          [`earned`]: earnedData.length === 0 ? 0 : earnedData[index].dateImpressions.value,
        };
        return dataPoint;
      });
    } else if (earnedData.length) {
      formattedData = earnedData.map((mention: LabelsCountByDate, index: number) => {
        const dataPoint = {
          category: mention.key_as_string,
          [`earned`]: mention.dateImpressions.value,
          [`paid`]: paidData.length === 0 ? 0 : paidData[index].dateImpressions.value,
        };
        return dataPoint;
      });
    }

    const root = am5.Root.new(`chartdiv1002`);

    setDataIndicator(formattedData, root, isLoading);

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    const yRenderer = yAxis.get(`renderer`);
    yRenderer.ticks.template.setAll({
      minPosition: 0.1,
      visible: true,
    });
    yRenderer.labels.template.setAll({
      minPosition: 0.1,
    });

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: `category`,
      }),
    );
    xAxis.data.setAll(formattedData);
    xAxis.set(`tooltip`, am5.Tooltip.new(root, {
      themeTags: [`axis`],
    }));

    // Create series
    function createSeries(name: string, field: string) {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name,
          xAxis,
          yAxis,
          valueYField: field,
          categoryXField: `category`,
          stacked: false,
          tooltip: am5.Tooltip.new(root, {
            labelText: `${name}: {valueY}`,
            minHeight: 20,
            scale: 0.70,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 6,
          }),
        }),
      );
      series.columns.template.setAll({
        cornerRadiusTL: 2,
        cornerRadiusTR: 2,
        width: 10,
      });

      series.columns.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });
      series.data.setAll(formattedData);

      // Add animation
      series.appear();
    }

    createSeries(`Earned`, `earned`);
    createSeries(`Paid`, `paid`);

    // Add cursor
    chart.set(`cursor`, am5xy.XYCursor.new(root, {}));

    const updatedStatus = {
      ...chartsLoadingStatusVar(),
      earnedAndPaidImpressions: isLoading,
    };
    chartsLoadingStatusVar(updatedStatus);

    return () => {
      root.dispose();
    };
  }, [
    paidData,
    earnedData,
  ]);

  return (
    <ChartContainer
      chartId="chartdiv1002"
      title={`Earned and Paid By Date - ${isNielsenUser ? `Nielsen` : `Numeris`} Impressions`}
      chartStyles={{
        height: `275px`,
        justifySelf: `end`,
      }}
      error={paidMentionsError || earnedMentionsError}
    />
  );
};

export default EarnedAndPaidImpressions;
