import { useMemo } from 'react';
import { useParams } from 'react-router';
import useAudienceAnalytics from 'hooks/useAudienceAnalytics';
import { timezoneVar } from 'apollo/cache';

// helper function to determine if a given status type (by id) exists in a watchlist (ie: pending, paid, etc.)
const statusAvailability = (statusKey: number) => {
  const { watchlistId } = useParams();

  let isStatusIncluded: boolean = false;
  const {
    searchMentions: audienceMentions,
    loading: loadingMentions,
    error: errorMentions,
  } = useAudienceAnalytics({
    watchlistId,
    aggregate: [
      {
        name: `verificationStatus`,
        field: `mentionStatusId`,
        operator: `term`,
        sort: {
          criteria: `sum`,
          field: `impressions`,
        },
        aggregate: [
          {
            field: `mentionDate`,
            operator: `date`,
            format: `MM/dd/yy`,
            timezone: timezoneVar(),
            interval: `1d`,
            aggregate: [
              {
                field: `impressions`,
                operator: `sum`,
              },
            ],
          },
        ],
      },
    ],
  });

  const labelsByDateImpressionsData = useMemo(() => audienceMentions?.jsondata.aggregations.verificationStatus.buckets, [audienceMentions]) || [];

  labelsByDateImpressionsData.forEach((status: any) => {
    if (status.key === statusKey) {
      isStatusIncluded = true;
    }
  });

  return {
    isStatusIncluded,
    loadingMentions,
    errorMentions,
    labelsByDateImpressionsData,
  };
};

export default statusAvailability;
