import * as am5 from '@amcharts/amcharts5';

const setDataIndicator = (chartData: any[], root: any, loading: boolean) => {
  const indicator = root.container.children.push(am5.Container.new(root, {
    width: am5.p100,
    height: am5.p100,
    layer: 1000,
    background: am5.Rectangle.new(root, {
      fill: am5.color(0xffffff),
      fillOpacity: 1,
    }),
  }));

  indicator.children.push(am5.Label.new(root, {
    text: loading ? `Loading...` : `No data available`,
    fontSize: 25,
    x: am5.p50,
    y: am5.p50,
    centerX: am5.p50,
    centerY: am5.p50,
  }));

  function toggleDataIndicator() {
    if (chartData.length > 0 && !loading) {
      indicator.hide();
    } else {
      indicator.show();
    }
  }

  toggleDataIndicator();
};

export default setDataIndicator;
