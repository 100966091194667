import { InMemoryCache, makeVar } from '@apollo/client';
import { TargetAudience } from 'types';
import initialTargetAudienceData from '../constants';

export const isAuthenticatedVar = makeVar<boolean>(false);
export const authTokenVar = makeVar<string>(``);
export const targetAudienceVar = makeVar<TargetAudience>(initialTargetAudienceData);
export const isTAFilteredVar = makeVar<boolean>(false);
export const isAppErrorVar = makeVar<boolean>(false);
export const appErrorMessageVar = makeVar<string>(``);
export const chartsLoadingStatusVar = makeVar<{}>({});
export const timezoneVar = makeVar<string>(``);
export const watchlistIdVar = makeVar<string>(``);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isAuthenticated: {
          read: () => isAuthenticatedVar,
        },
        targetAudience: {
          read: () => targetAudienceVar,
        },
        isTAFiltered: {
          read: () => isTAFilteredVar,
        },
        timezone: {
          read: () => timezoneVar,
        },
      },
    },
  },
});
