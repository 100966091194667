import ToggleButton from '@mui/material/ToggleButton';
import '@fontsource/nunito/500.css';

type Props = {
  title: string
  value: number
}

const FilterButton = ({
  title,
  value,
  ...props
}: Props) => (
  <ToggleButton
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    value={value}
    sx={{
      textTransform: `unset`,
      fontFamily: `'Nunito', sans-serif`,
      fontSize: `14px`,
      fontWeight: `600`,
      border: `none`,
      borderRadius: `4px !important`,
      padding: `8px 16px 8px 16px`,
      margin: `0 10px 0 10px`,
      color: `#2a323c`,
      '&&.MuiButtonBase-root': {
        '&&.Mui-selected': {
          color: `white`,
          backgroundColor: `#1871e8`,
        },
      },
    }}
  >
    {title}
  </ToggleButton>
);

export default FilterButton;
